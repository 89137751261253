import React, { useState, useEffect, useContext} from 'react'
import Box from '@mui/material/Box';

const mathValue = (bks,lock,cargo,setter) => {
    const value = parseInt(bks) + parseInt(lock) - parseInt(cargo)
    setter(value)
}

const DispbksBox = ({bks,cargo, state}) => {
    const [lockCargo, setLockCargo] = useState(cargo);
    const [bksValue, setBksValue] = useState(bks);
  
    
    useEffect(() => {
        mathValue(bks,lockCargo,cargo,setBksValue)
    },[state.bksChange])

    return(
    <>
        <Box>
            {bksValue}
        </Box>
    </>
    )
}   

export default DispbksBox