import React, { useState, useEffect, useContext } from 'react';
import { DistributionContext } from '.';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { aplicateSugestions, ReverseSugestions } from './functionsCore';

const AplicateIcon = ({num,infos}) => {
    const {state,setState} = useContext(DistributionContext)
    const [color,setColor] = useState(infos['color']); 
    const [action,setAction] = useState(false)
    
    const execute = () => {

        if(action !== true){
            setColor('#00c61f')
            infos.color = '#00c61f'
            aplicateSugestions(num,infos,state.consolidatedStep4)
            setAction(true)
            infos['action'] = true
        }else{
            infos.color = 'gray'
            setColor('gray')
            ReverseSugestions(num,infos,state.consolidatedStep4)
            setAction(false)
            infos['action'] = false
            
        }

        setState({consolidatedStep4:state.consolidatedStep4,changedTables:!state.changedTables})
   
    }

    const alterStates = () => {

        if(infos.action === true && action === true){
            setColor('gray')
            setAction(false)
            
        }
        infos.action = false
        
        
    }

    const alterColor = () => {
        if(infos.action === true ){
            setColor('#00c61f')
            setAction(true)
        }
        
    }

    useEffect(() => {
        alterStates()
      },[state.resetSugestions])

    useEffect(() => {
        alterColor()
      },[state.colorSugestions])




    return(
        <>
          <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete">
                    <CheckCircleIcon onClick={execute} sx={{color:color}}  />
                </IconButton>
                }
                >
            </ListItem>

           
        </>
    )
}

export default AplicateIcon;