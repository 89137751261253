import React, { useReducer,useContext, createContext, useEffect, useState } from 'react'
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import { defaultReducer } from '../Utils/reducers'
import {api} from '../../services/api'
import { Box } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import {customTheme} from '../theme/textfieldTheme';
import './style.css'
import {externalComponent} from '../AppRoutes'
import { toast } from 'react-hot-toast';
import ExecuteModal from './execute-modal';
import ModalPendency from './modalPendency';
import ModalOrders from './modalOrders';
import clsx from 'clsx';
import { sortTables, compareDates, formatDate } from './functionsCore';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { number2dayweek } from '../Utils/dayjsptbrhelper';

export const DistributionContext = createContext()

export const columns = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.7,
            cellClassName: () => {
                return clsx('default-app bold', {
                    selected: true ,
                });
            }
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.9,
            align: 'left',
            cellClassName: () => {
                return clsx('default-app bold align-start', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estba',
            headerName: 'Est BA',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estce',
            headerName: 'Est CE',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estmt',
            headerName: 'Est MT',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estpe',
            headerName: 'Est PE',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estse',
            headerName: 'Est SE ',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estpb',
            headerName: 'Est PB ',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estrn',
            headerName: 'Est RN',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estpa',
            headerName: 'Est PA',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venba',
            headerName: 'Ven BA',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'vence',
            headerName: 'Ven CE',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venmt',
            headerName: 'Ven MT',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venpe',
            headerName: 'Ven PE',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'vense',
            headerName: 'Ven SE',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venpb',
            headerName: 'Ven PB',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venrn',
            headerName: 'Ven RN',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'venpa',
            headerName: 'Ven PA',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app ven-tables', {
                    selected: true ,
                });
            }
        },
        {
            field: 'dispbks',
            headerName: 'BKS Disp',
            flex: 1.2,
            cellClassName: () => {
                return clsx('default-app dep-bks', {
                    selected: true ,
                });
            }
        }
    ]

const convertDateFormat = (dateString) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        const date = new Date(dateString);
    
        const formattedDate = date.toISOString().slice(0, 10);
    
        return formattedDate;
    } else {
        const date = new Date(dateString);
    
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    
        return formattedDate;
    }
}



const currentDate = new Date();
const fourMonthsAgo = new Date();

fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

fourMonthsAgo.setDate(1);

var dtFinal = convertDateFormat(currentDate)
var dtInicial = convertDateFormat(fourMonthsAgo)

export const distributionInitialState = {
        renderRow: null, data:[],dataToResetDist:[],dtInicial:dtInicial,dtFinal:dtFinal,
        modalState:false, step:0, selectedFilial:"",selectedFilialVen:"",
        columnsDatagrid:"", distrA:[],distrB:[], branches:"", consolidatedListA:[],
        consolidatedListB:[], functionStep3:"",rowHeight:30,validateStep3:"", consolidatedStep4:[],
        modalStep2:false, totalAB:"", modalPendency:false,numTruck:0,
        dataToReset:[],sugestions:{},changedTables:false, resetSugestions:false,colorSugestions:false,
        modalOrders:false, sendStep3:false,sendStep4:false,modalPrevious:false,reservBranch:"",reservBranchName:"",
        branch:"", updateStep4:false,message:"", bksChange:false
}

const Distribution = () => {

    const outerTheme = useTheme();
    const {setLoader} = useContext(externalComponent)

    const [state, setState] = useReducer(defaultReducer, {
        ...distributionInitialState
    })

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
       
    const getDistribution = async () => {
        const body = {
            "dt_inicial": formatDate(state.dtInicial),
            "dt_final": formatDate(state.dtFinal)
        }
      
        if( state.dtInicial === "" || state.dtFinal === ""){
            toast.error('Preencha todas as datas !')
        }else{

            if(compareDates(formatDate(state.dtInicial),formatDate(state.dtFinal)) === false){
                toast.error('Data inicial não pode ser maior que a final !')
            }else{
                setLoader(true)
                const res = await api.post('/distribution/',body)
                if (res.status === 200){
                    const sorted_table = sortTables(res.data)
                 
                    setState({data: sorted_table,dataToResetDist:JSON.stringify(sorted_table) })
                   
                }
                setLoader(false)
            }
         }
    }

    const setInicialDate = (e) => { 
        const value = e.format('YYYY-MM-DD')    
        setState({dtInicial:value});
    }

    const setFinalDate = (e) => {
        const value = e.format('YYYY-MM-DD') 
        setState({dtFinal:value});
    }

    const clearAllFilters = () => {
        setState({dtInicial:""});
        setState({dtFinal:""});
    }

    const openModal = () => {
        
        if(state.data.length === 0){
            toast.error('Consulte os dados primeiro para executar !')
        }else{
            setState({modalState:true})
        }
        
    }

    const openModalPendency = () => {
    
        setState({modalPendency:true})
    }

    const openModalOrders = () => {
    
        setState({modalOrders:true})
    }

    const getBranches = async () => {
        setLoader(true)
        const res = await api.get('/branches/')
    
        if (res.status === 200){

            const uniqueObject = {};

            for (const key in res.data) {
                const id = res.data[key].id;
                if (!uniqueObject[id]) {
                    uniqueObject[id] = res.data[key];
                }
            }

            setState({branches: uniqueObject
            })
        }
        setLoader(false)

    }

    const handleColumnVisibilityChange = (params) => {
       setColumnVisibilityModel(params)
       const stringColumn = JSON.stringify(params)
       localStorage.setItem('datagrid0', stringColumn );
      };

    useEffect(() => {
        
        const storedColumns = localStorage.getItem('datagrid0');
        if (storedColumns) {
            setColumnVisibilityModel(JSON.parse(storedColumns));
        }

        getBranches()

      
    },[])


    window.state = () => {
        console.log(state)
    }

    return (
        <>
    {
            <>
        <DistributionContext.Provider value={{state,setState}}>

            <ExecuteModal/>
            <ModalPendency/>
            <ModalOrders/>

            <ThemeProvider theme={customTheme(outerTheme)}>
                <Box style={{display:'flex',flexDirection: 'column',border:'2px solid #e0e0e0',borderRadius:'5px'}}>

                    <Box style={{gap:'1rem', display:'flex', padding:'1rem 1rem 0 1rem',flexDirection: 'row', justifyContent: 'space-around',width:'32rem'}}>
             
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="Dt Vendas Inicial:"
                            size="small" 
                            value={dayjs(state.dtInicial)}  
                            onChange={setInicialDate} 
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n) => number2dayweek[n.$W]}
                            />
                        </DemoContainer>
                
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="Dt Vendas Final:" 
                            size="small" 
                            value={dayjs(state.dtFinal)} 
                            onChange={setFinalDate} format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n) => number2dayweek[n.$W]}
                            />
                        </DemoContainer>
            
                    </Box>
                    
                    <Box  style={{display:'flex', justifyContent:'space-between', padding: '1rem' }}>
                        <Box style={{display:'flex',flexDirection: 'row', gap:'1rem'}}>
                            <ColorButton  className='realpadding' onClick={getDistribution}>Consultar</ColorButton>

                            <ColorButton className='realpadding' onClick={clearAllFilters}>Limpar Filtros</ColorButton>
                        </Box>

                        <Box style={{display:'flex',flexDirection: 'row',gap:'1rem'}}>

                            <ColorButton  className='realpadding' onClick={openModalOrders}>Cargas finalizadas</ColorButton>

                            <ColorButton  className='realpadding' onClick={openModalPendency}>Visualizar pendentes</ColorButton>

                            <ColorButton  className='realpadding' onClick={openModal}>Calcular</ColorButton>
                        </Box>
                    </Box>
                        
                </Box>

            </ThemeProvider>

                <DataGrid
                    className='alterned_grid'
                    sx={{
                        '& .default-app.selected': {
                            justifyContent:'center',
                            alignItems:'center',
                        },
                        height:"100%",
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            justifyContent: 'center',
                          },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color:'black'
                        }
                      
                    }}

                    slots={{ toolbar: GridToolbar }}
                    rows={state.data}                                                                                                                                                                                                                                                                                                                                                                                                                       
                    columns={columns}
                    pagination={false}
                    density='compact'
                    rowHeight={state.rowHeight}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        handleColumnVisibilityChange(newModel)
                      }
                />
            
        </DistributionContext.Provider>

        </>
    }
        </>)
}

export default Distribution;

