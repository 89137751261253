import React,{useState, useContext, useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import BoxInfo from './boxInfo';
import BoxSizeTruck from './boxSizeTruck';
import { toast } from 'react-hot-toast';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Checkbox from '@mui/material/Checkbox';
import DefaultModal from '../Utils/DefaultModal';
import ModalTransfer from './modalTransfer';
import { DistributionContext } from '.';
import { externalComponent } from '../AppRoutes';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalText from './modalText';
import ColorButton from '../Buttons/ColorButton';
import SendBoxVisualization from './senboxVisualization';
import { api } from '../../services/api';
import { updateCodproInfo } from './functionsCore';
import MessageSend from './messageSend';
import DispbksBox from './disbksBox';

const DatagridVisualization = ({index,lines,table,m3,peso,envio,truck_size,hash}) => {

    const {state,setState} = useContext(DistributionContext)
    const { setLoader } = useContext(externalComponent)

    const [modalStatus,setModalState] = useState(false)
    const [rowToTransfer,setRowToTransfer] = useState("")
    const [modalDelete,setModalDelete] = useState(false)
    const [rowToDelete,setRowToDelete] = useState("")

  const transferFunc = (row) => {
    let tables = state.consolidatedStep4
    
    if(tables.length > 1){
      setRowToTransfer(row)
      setModalState(true)
    }else{
      toast.error('Não há caminhões para transferência.')
    }

    
  }

  const closeModal = () => {
    setModalState(false)
  }

  const closeModalDelete = () => {
    setModalDelete(false)
  }

  const deleteRow = (row) => {
    setRowToDelete(row.codpro)
    setModalDelete(true)
  }


  const confirmDelete = () => {
    

      for ( const each of state.consolidatedStep4 ){
        
        if(each[table]){
          const lines =  each[table]['lines']
          const filteredLines = lines.filter(item => item.codpro !== rowToDelete);
          each[table]['lines'] = filteredLines

        }
      }

      setState({consolidatedStep4:state.consolidatedStep4})
      closeModalDelete()
  }

  const buttonConfirmDelete = () => {
    return(
    <Box>
        <ColorButton onClick={confirmDelete} >Sim </ColorButton>
    </Box>
    )
}

  const convertCheckedValue = (value) =>{
    if(value === 'true'){
      return true
    }else{
      return false
    }
  }

  const changeChecked = (e,row) => {
    const checked = e.target.checked
    const rowId = row.id

    for(const each of state.consolidatedStep4){

        if(each[table]){
          const lines = each[table].lines

          for(const line of lines){

            if(line.id === rowId){
              line['checked'] = String(checked)
            }

          }
      }

    }
   
  }
  



    const columnsMain = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.6,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'codpro',
            headerName: 'Codpro',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'descr',
            headerName: 'Descr',
            flex: 1.5,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'peso',
            headerName: 'Peso',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'm3',
            headerName: 'M3',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: `est${state.branch}`,
            headerName: `Est ${state.branch.toUpperCase()}`,
            flex: 0.3,
            renderCell: ({value}) => (
              <Box className='est-tables' sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
          },
          {
            field: `disp${state.branch}`,
            headerName: `Disp ${state.branch.toUpperCase()}`,
            flex: 0.3,
            renderCell: ({value}) => (
              <Box className='disp-tables' sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'dispbks',
            headerName: 'BKS Disp',
            flex: 0.3,
            renderCell: ({value,row}) => (
              <Box className='dep-bks' sx={{padding:'0px 10px'}}>
                <DispbksBox  bks={value} cargo={row.cargo} state={state}/>
              </Box>
            )
          },
          {
            field: 'cargo',
            headerName: 'Envio',
            flex: 0.5,
            renderCell: (params) => (
            
            <Box sx={{padding:'0px 10px'}}>
              
              <SendBoxVisualization envio={params.row.cargo} codpro={params.row.codpro}
              table={table}
              />
            </Box>
            )
          }
          ,
          {
            field: 'acoes',
            headerName: 'Ações',
            renderCell: (params) => (
              <Box>
                <ImportExportIcon className='actionIcon' sx={{fontSize:'1.3rem'}} onClick={() => transferFunc(params.row)} />
                <Checkbox checked={convertCheckedValue(params.row.checked)} onClick={(event) => changeChecked(event,params.row)} />
                <DeleteForeverIcon onClick={() => deleteRow(params.row)} className='actionIcon' sx={{fontSize:'1.3rem'}}/>
              </Box>
            )
          }
    ]

    const getCodproInfos = async () => {
      const branch = state.branch
      const codpros = lines.map(each => each['codpro'])

      const body = {
        "branch":branch,
        "codpros":codpros
      }

      setLoader(true)
      const res = await api.post(`/codproinfo/`, body)
      if (res.status === 200) {
          const data = res.data
          updateCodproInfo(data,lines)
      }
      setLoader(false)
      setState({sendStep4:!state.sendStep4,bksChange:!state.bksChange})
    }

    useEffect(() => {
      getCodproInfos()

      
      
    },[state.updateStep4])

    return(
        <>
        <br/> 

        <DefaultModal dialogProps={{maxWidth:'xs'}} open={modalDelete} handleClose={closeModalDelete} content={<ModalText text={'Tem certeza que deseja deletar esta linha ?'}/>} action={buttonConfirmDelete()}
            title={' Deletar linha '}/>

        <DefaultModal dialogProps={{maxWidth:'xs'}} open={modalStatus} handleClose={closeModal} content={<ModalTransfer table={table} row={rowToTransfer} handleClose={closeModal}/>} 
            title={' Transferência de carga '}/>

            <Box sx={{display:'flex',flexDirection:'row',gap:'1rem', justifyContent:'space-between'}} >

                <Box sx={{flex:'0.8',display:'flex',height:'28rem'}} >
                    <DataGrid 
                                className='alterned_grid'
                                rows={lines}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsMain}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                sx={{
                                maxHeight:"30rem",
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                  justifyContent: 'center',
                                }
                                }}
                                rowHeight={27}
                    />
                </Box>

                <Box sx={{display:'flex',flexDirection:'column', flex:'0.2',justifyContent: index > 1 ? 'flex-start' : 'space-between', gap: index > 1 ? '1rem' : '0rem'}}>
                  
                  <Box sx={{display:'flex',flexDirection:'row',gap:'1rem'}}>

                    <BoxInfo title={'Volume Total (m³)'} value={m3} colorBack={'#c60000'} color={'white'} />

                    <BoxSizeTruck title={'Volume do caminhão (m³)'} truck_size={truck_size} table={table} colorBack={'#c60000'} color={'white'}/>

                  </Box>

                  <Box sx={{display:'flex',flexDirection:'row', gap:'1rem'}} >

                    <BoxInfo title={'Peso total (kg)'} value={peso} colorBack={'#c60000'} color={'white'} />

                    <BoxInfo title={'Total de pneus'} value={envio} colorBack={'#c60000'} color={'white'} />

                  </Box>
                  
                  {
                    index > 1 ?
                    <></>
                    :
                    
                    <MessageSend type="adm" hash={hash}/>
                  }
                  

                </Box>

            </Box>
        </>
    )

}

export default DatagridVisualization;