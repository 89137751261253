import { getSession } from "../../contexts/auth";

export var isAdmin = null
export var isAssociate = null
export var isClient = null

export var notAdmin = null
export var notAssociate = null
export var notClient = null

export var groups = null

export const resetPermissions = () => {
    const newuser = getSession()

    groups = newuser?.groups
    
    const bits = groups?.bits

    isAdmin = Boolean(1&bits)
    isAssociate = Boolean(2&bits)
    isClient = Boolean(4&bits)
    notAdmin = Boolean(1&~bits)
    notAssociate = Boolean(2&~bits)
    notClient = Boolean(4&~bits)
}

export const hasPermissions = (...arr) => {
    let grupos = sessionStorage.getItem("grupo");



    grupos = grupos?.split(',')?.map(each => each.trim())

    return grupos?.some(each => arr.includes(each))
}

export const hasPermissions2 = (...arr) => {
    let grupos = sessionStorage.getItem("grupo");
    let user = JSON.parse(sessionStorage.getItem("user"))
  
    if(user === "IGORDANTAS"){
        return false
    }

    grupos = grupos?.split(',')?.map(each => each.trim())

    return grupos?.some(each => arr.includes(each))
}

